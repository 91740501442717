import { decodeCustomBase64 } from "./base64";

const S_UNKNOWN = "unknown";

export const MEASURE_KEY = "m";
export const DIMENSION_KEY = "d";
export const TIME_SERIES_KEY = "t";

const R_TIME_SERIES = /^t[1-9]\d*|t0$/;
const R_TIME_GROUP_FUNC = /^groupByF_/;
const R_DIMENSION = /^d[1-9]\d*$/;
const R_DIMENSION_MATCH = /^d[1-9]\d*=[0-9A-Za-z+/-]+$/;
const R_DIMENSION_ATTR = /^d[1-9]\d*\.[0-9a-zA-Z]+$/;
const R_DIMENSION_ATTR_MATCH = /^d[1-9]\d*\.[0-9a-zA-Z]+=[\w+/-]+$/;
const R_MEASURE = /^m[1-9]\d*$/;
const R_DATE_TAG = /^dateTag_[\w+/-]+$/;
const R_KEYWORD = /^keyword_/;
const R_DATE_RANGE = /^dateRange_\d{16}$/;
const R_DATE_BEFORE = /^dateBefore_[1-9]\d*[smhdwMQy]$/;
const R_DATE_POINT = /^datePoint_\d{8}$/;
const R_DATETIME_RANGE = /^datetimeRange_\d{28}$/;
const R_DATETIME_POINT = /^datetimePoint_\d{14}$/;
const R_SHORT_CUT = /^shortcut_/;
const R_CUSTOM_TIME_CUT = /^offset_/;
const R_COMPARE_TIME_CUT = /^compare_/;
const R_DATE_LIST = /^dateList_/;
const R_DATA_RELA = /^dateRela_/;

// 是否已经解析出具体含义
const isKnown = function (prop: string): boolean {
  return prop && prop !== S_UNKNOWN;
};

// 是否是时间范围
export const isDataRange = function (prop: string): boolean {
  return (
    R_DATE_RANGE.test(prop) ||
    R_DATE_BEFORE.test(prop) ||
    R_DATE_POINT.test(prop) ||
    R_DATETIME_RANGE.test(prop) ||
    R_DATETIME_POINT.test(prop)
  );
};

export const getIdByProp = function (prop: string): string {
  const keys = [MEASURE_KEY, DIMENSION_KEY, TIME_SERIES_KEY];
  const k = keys.find(k => prop.includes(k));
  return prop.split(k)[1];
};

// 是否是指标
export const isMeasureProp = function (prop: string): boolean {
  return R_MEASURE.test(prop);
};

// 是否是维度
export const isDimensionProp = function (prop: string): boolean {
  return R_DIMENSION.test(prop);
};

// 是否是维度表维度
export const isDimensionAttr = function (prop: string): boolean {
  return R_DIMENSION_ATTR.test(prop);
};

// 是否是时间序列
export const isTimeSeriesProp = function (prop: string): boolean {
  return R_TIME_SERIES.test(prop);
};

// 是否是时间分组函数
export const isTimeGroupFunc = function (prop: string): boolean {
  return R_TIME_GROUP_FUNC.test(prop);
};

// 是否是维度匹配
const isDimensionMatch = function (prop: string): boolean {
  return R_DIMENSION_MATCH.test(prop);
};

// 是否是维度表维度匹配
const isDimensionAttrMatch = function (prop: string): boolean {
  return R_DIMENSION_ATTR_MATCH.test(prop);
};

// 是否是自定义规则
const isCustomRule = function (prop: string): boolean {
  return R_SHORT_CUT.test(prop);
};

// 是否是自定义时间周期
export const isCustomTimePeriod = function (prop: string): boolean {
  return R_CUSTOM_TIME_CUT.test(prop);
};

// 是否是同环比
export const isCompare = (prop: string): boolean => R_COMPARE_TIME_CUT.test(prop);

// 是否是时间相关 prop
export const isDateRelated = function (prop: string): boolean {
  return (
    R_DATE_TAG.test(prop) ||
    R_DATE_BEFORE.test(prop) ||
    R_DATE_POINT.test(prop) ||
    R_COMPARE_TIME_CUT.test(prop) ||
    R_DATE_RANGE.test(prop) ||
    R_DATA_RELA.test(prop)
  );
};

// 是否是时间范围
export const isDateRangeRelated = function (prop: string): boolean {
  return R_DATE_LIST.test(prop) || R_DATE_RANGE.test(prop);
};

/*
根据 prop 获取 Token 类型
1: 时间范围
2: 指标
3: 维度
4: 关键字
5: 未知
6: filter
7: 日期标签
8: 内置关键词
*/
export const getTokenTypeByProp = function (prop: string): number {
  if (isDataRange(prop)) return 1;
  if (isMeasureProp(prop)) return 2;
  if (isDimensionProp(prop) || isDimensionAttr(prop) || isTimeSeriesProp(prop) || isTimeGroupFunc(prop)) return 3;
  if (isDimensionMatch(prop) || isDimensionAttrMatch(prop)) return 6;
  if (R_DATE_TAG.test(prop)) return 7;
  if (R_KEYWORD.test(prop)) return 8;
  if (isCustomRule(prop)) return 9;
  if (!isKnown(prop)) return 5;
  return 4;
};

// 根据 prop 获取等号后面的编码数值
const getDimensionPropEncodeValue = (prop: string) => {
  if (prop.includes("value_")) return prop.replace("value_", ""); // 映射数据结构
  if (prop.includes("=")) {
    // 普通维度值可搜索的维度数据内容
    const prefix = prop.split("=")[0];
    return prop.substring(prefix.length + 1);
  }
  return prop;
};

const getDimensionPropDecodeValue = (prop: string) => decodeCustomBase64(getDimensionPropEncodeValue(prop));

export const resetDimensionTokensWordByProp = tokens => tokens.map(item => ({ ...item, word: getDimensionPropDecodeValue(item.prop) }));
