import { getLocaleText } from "@/utils/locale";

const getText = id => getLocaleText(`components.Constants.PinboardSource.${id}`);

export default class PinboardSource {
  static STARS = "stars";

  static SHORTCUTS = "shortcuts";

  static TRASH = "trash";

  static RECENT = "recent";

  static SHARE_TO_ME = "share-to-me";

  static MINE = "mine";

  static ALL = "all";

  static TEMPLATES = "templates";

  static SHARE_TO_ALL = "share-to-all";

  static choices = [this.RECENT, this.MINE, this.SHARE_TO_ALL, this.SHARE_TO_ME, this.STARS, this.TRASH];

  static isShortcutsTab = tabId => tabId.startsWith(this.SHORTCUTS);

  static getNewText = k => {
    if (k === this.RECENT) return getText("recent");
    if (k === this.MINE) return getText("mine");
    if (k === this.SHARE_TO_ALL) return getText("shareToAll");
    if (k === this.SHARE_TO_ME) return getText("shareToMe");
    if (k === this.STARS) return getText("stars");
    if (k === this.TRASH) return getText("trash");
    if (k === this.SHORTCUTS) return getText("shortcuts");
  };
}
