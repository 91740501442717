export default {
  name: "用户名",
  username: "登录名",
  email: "用户邮箱",
  description: "用户介绍",
  role: "用户角色",
  groups: "所在用户组",
  disableDownload: "禁止下载数据",
  notSet: "未设置",
  status: "状态",
  operations: "操作",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  removeAccountBtnText: "移除",
  removeAccountConfirmTitle: "确认移除此用户？",
  removeAccountFormerTip: "用户有负责的报告",
  removeAccountLatterTip: "移除前，需要将该用户负责的报告删除或转移给本工作区其他用户",
  removeAccountConfirmText: "确认并处理",
  removeAccountSuccessToast: "用户移除成功",
  disableAccountFormerTip: "确认禁用此用户？",
  disableAccountLatterTip: "禁用后用户将无法进入此工作区",
  configModalTitle: "功能配置",
  configBtnText: "功能",
  editAccountBtnText: "权限",
  tier: "账号类型",
  saveSuccessToast: "保存成功",
  allGroups: "全部用户组",
  addAccountBtnText: "添加用户",
  batchAddAccountBtnText: "批量导入",
  searchAccountInputPlaceholder: "请输入想搜索的用户信息",
  searchGroupInputPlaceholder: "请输入想搜索的用户组信息",
  selectGroupPlaceholder: "请选择用户组",
  addAccountModalTitle: "添加用户",
  addAccountOkText: "确认添加",
  addAccountInputPlaceholder: "请输入想添加的用户信息",
  selectFormerText: "请选择",
  addSuccessToast: "添加成功",
  removeSuccessToast: "移除成功",
  createdAt: "加入时间",
  uploadModalTitle: "批量上传",
  uploadTip: "请先下载模板，按模板格式填写后上传",
  formatTip1: "以登录名为准，将系统中的用户批量加入此工作区和对应用户组 支持更新用户组信息（即根据最新的文件自动新建用户组和更新成员）",
  formatTip2: "支持格式：.csv",
  formatTip3: "支持编码格式：utf-8，gbk，ascii，utf-16",
  uploadBtnText: "选择文件",
  downloadBtnText: "下载模板",
  downloadMembersFileTitle: "批量上传成员模板",
  downloadDataBtnText: "下载当前成员",
  okBtnText: "确定",
  groupsTip: "有多个用户组用逗号分隔",
  uploadSuccessToast: "文件上传成功",
  uploadFailToast: "文件上传失败",
  downloadSuccessToast: "下载成功",
  uploadErrorToast: "用户信息上传错误",
  errorFilename: "报错信息",
  errorRow: "报错行数",
  errorMessage: "报错信息",
  errorField: "报错列",
  uploadErrorTitle: "上传失败",
  uploadErrorDesc: "请下载错误信息，根据信息修改后再次上传",
  uploadErrorBtnText: "下载错误信息文件",
  fileModelName: "工作区用户成员模板.csv",
  off: "关闭",
  on: "开启",
};
