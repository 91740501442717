export default {
  name: "用户组名称",
  nameInputPlaceholder: "请输入用户组名称",
  nameInputErrMsg: "请输入用户组名称",
  description: "用户组介绍",
  descriptionInputPlaceholder: "请输入用户组介绍",
  numberUsers: "用户数",
  autoAddUsers: "自动添加用户",
  disableDownload: "禁止下载数据",
  on: "开启",
  off: "关闭",
  operations: "操作",
  settingsBtn: "设置",
  editBtnText: "成员与权限",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除此用户组？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  addBtnText: "添加用户组",
  searchGroupInputPlaceholder: "请输入想搜索的用户组信息",
  addUserGroupModalTitle: "新增用户组",
  saveSuccessToast: "修改成功",
  groupOptionsModalTitle: "用户组设置",
  batchAddAccountBtnText: "批量导入",
  uploadLabel: "更新方式",
  addUserBtn: "新增用户",
  coverUserBtn: "覆盖用户",
  uploadTypeTip: "只会增加或修改当前用户组的成员信息，不会删除",
  uploadFailedToast: "上传失败",
  loginName: "登录名",
  formatTip1: "以登录名为准，将当前工作区中的用户批量加入此用户组",
  formatTip2: "支持格式：.csv",
  formatTip3: "支持编码格式：utf-8，gbk，ascii，utf-16",
  fileModelName: "用户组成员模板.csv",
  downloadSuccessToast: "下载成功",
  uploadSuccessToast: "文件上传成功",
  uploadModalTitle: "批量上传",
  uploadBtnText: "选择文件",
  downloadBtnText: "下载模板",
  downloadDataBtnText: "下载当前成员",
  uploadTip: "请先下载模板，按模板格式填写后上传",
  uploadWarningTitle: "无效用户提醒",
  uploadWarningDesc: "请下载无效用户列表信息查看具体原因",
  uploadWarningBtnText: "下载无效用户列表",
  warningFile: "无效用户列表",
  userDoesNotExist: "用户不存在",
  notInWorkspace: "用户不在工作区",
  WrongUser: "错误用户",
  ErrorMessage: "报错信息",
  uploadSuccessTitle: "上传成功",
  uploadAddDesc: "新增用户数",
  unicode: "：",
};
