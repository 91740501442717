import dayjs from "dayjs";
import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  AccountTier,
  AccountType,
  MessageCardDetail,
  PinboardPermission,
  PinboardShareRequestStatus,
  ReminderMethod,
} from "@/components/Constants";
import GlobalContext from "@/contexts/GlobalContext";
import { changeProject, redirectToIndex } from "@/utils/changeProject";
import { isTrueArray } from "@/utils/common";
import { EN_US, getLocaleText } from "@/utils/locale";

import styles from "./index.module.scss";

const STRONG = "strong";
const LINK = "link";
const GRAY = "gray";

const getText = id => getLocaleText(`components.MessageCardContent.${id}`);

const MessageCardContent = ({ message, hasStyle = true }) => {
  const history = useHistory();
  const match = useRouteMatch();
  // 强制刷新
  const { setRefreshKey } = useContext(GlobalContext);

  const {
    visitorName,
    groupVisitorUid,
    groupVisitorName,
    projectOwnerName,
    pinboardName = "",
    projectId,
    pinboardUid,
    projectName = "",
    referrerName,
    approvalStatus,
    oldTier, // 账户修改前等级
    tier, // 账户修改后等级
    type, // 账户修改后类型
    oldType, // 账户修改前类型
    expiredAt, //  临时账户的过期时间
    pinboardIsDeleted: isPinboardDeleted,
    originText, // 搜索反馈 原始文本
    tokens, // 搜索反馈 当前匹配
    expectedTokens, // 搜索反馈 请求匹配
    channel, // 定时提醒 提醒方式
    channels, // 告警 提醒方式
    owner, // 报告负责人
    alertName, // 告警名称
    visitPermission, // 报告权限
  } = message?.content || {};

  const getStrongText = value => (hasStyle ? <span className={styles.strong}>{value}</span> : value);

  // 用户组名字
  const getGroupName = () =>
    groupVisitorName ? (
      <span>
        {getStrongText(groupVisitorName)}
        {getText("userGroup")}
      </span>
    ) : (
      getText("deletedUserGroup")
    );

  // 任何用户名都需要筛一下
  const getName = name => <span className={styles.link}>{name}</span> || <span className={styles.gray}>{getText("loggedOutUsers")}</span>;

  // 权限申请人姓名
  const getVisitorName = () => (groupVisitorUid ? getGroupName() : getName(visitorName));

  const getYourGroup = () =>
    groupVisitorUid ? (
      <span>
        {getText("yourIn")}
        {getGroupName()}
      </span>
    ) : (
      <span className={styles.link}>{getText("yourIn")} </span>
    );

  // 发送人姓名
  const getSenderName = () => getName(message?.sender?.nickname);

  const renderWorkspaceOwner = () => getName(projectOwnerName);

  const renderPinboardName = style => {
    const pinboard = pinboardName || getText("deletedReports");
    if (!hasStyle) return pinboard;
    if (isPinboardDeleted || !pinboardName) style = GRAY;
    switch (style) {
      case GRAY:
        return <span className={styles.gray}>{pinboard}</span>;
      case LINK:
        return (
          <span
            style={{ cursor: "pointer" }}
            className={styles.link}
            onClick={e => {
              e.stopPropagation();
              history.push(`/share/projects/${projectId}/pinboards/${pinboardUid}/`);
            }}
          >
            {pinboard}
          </span>
        );
      default:
        return <span> {pinboard} </span>;
    }
  };

  const renderWorkspaceName = style => {
    const workspaceName = projectName || getText("defunctWorkspace");
    if (!hasStyle) return workspaceName;
    if (!projectName) style = GRAY;
    switch (style) {
      case STRONG:
        return <span className={styles.strong}>{workspaceName}</span>;
      case GRAY:
        return <span className={styles.gray}>{workspaceName}</span>;
      case LINK:
        return (
          <span
            style={{ cursor: "pointer" }}
            className={styles.link}
            onClick={e => {
              e.stopPropagation();
              changeProject({
                projectId,
                callback: () => redirectToIndex({ match, setRefreshKey, history }),
              });
            }}
          >
            {workspaceName}
          </span>
        );
      default:
        return workspaceName;
    }
  };

  // TODO 等后端改成数组 现在有三个方式就会发三个消息
  // 定时提醒为 channel 字符串
  const renderToSetLoginMethodText = () => {
    const channelText = () => {
      if (channel === ReminderMethod.EMAIL) return getText("mailboxPush");
      if (channel === ReminderMethod.DINGTALK) return getText("dingtalkPush");
      if (channel === ReminderMethod.LARK) return getText("larkPush");
      if (channel === ReminderMethod.WECOM) return getText("wecomPush");
    };
    return channelText();
  };

  // 告警为 channels 数组
  const renderToSetLoginMethodsText = () => {
    const isEmail = channels[0] === ReminderMethod.EMAIL;
    const channelsText = () => `${channels.map(channel => ReminderMethod.getText(channel)).join("、")}${getText("robotPush")}`;
    return isEmail ? getText("mailboxPush") : channelsText();
  };

  switch (message.messageType) {
    case MessageCardDetail.CODE_1001:
      return (
        <div>
          {getText("yourAccountBecome")}
          {AccountTier.getText(oldTier)}
          {getText(AccountTier.isUpgrade(oldTier, tier) ? "updated" : "reducedTo")}
          {getStrongText(AccountTier.getText(tier))}
        </div>
      );
    case MessageCardDetail.CODE_1003:
      return type === AccountType.NORMAL ? (
        <>
          {`${getText("yourAccountBecome")}${AccountType.getText(oldType)}${getText("accountBecome")}`}
          <span className={styles.strong}>{`${AccountType.getText(type)}${getText("account")}`}</span>
        </>
      ) : (
        `${getText("currentAccount")}${AccountType.getText(type)}${getText("accountWill")}${dayjs(expiredAt).format("YYYY-MM-DD")}${getText(
          "expired"
        )}`
      );
    case MessageCardDetail.CODE_1004:
      return (
        <div>
          {getText("formerWillExpiredOneDay")}
          <span className={styles.strong}> {getText("oneDay")} </span>
          {getText("latterWillExpiredOneDay")}
        </div>
      );
    case MessageCardDetail.CODE_1101:
      return (
        <div>
          {getText("former1101")} {renderWorkspaceName(STRONG)} {getText("latter1101")}
        </div>
      );
    case MessageCardDetail.CODE_1102:
      return (
        <div>
          {getText("You")} {getText("removeYou")} {renderWorkspaceName(STRONG)}
          {getText("contactAdmin")}
          {renderWorkspaceOwner()}
          {getText("knowMore")}
        </div>
      );
    case MessageCardDetail.CODE_1901:
      return (
        <div>
          {getText("pin")} {renderPinboardName(GRAY)} {getText("deleted")}
        </div>
      );
    case MessageCardDetail.CODE_1902:
      return (
        <div>
          {getYourGroup()}
          <span className={styles.strong}> {getText("formerRemovedPin")} </span> {getText("latterRemovedPin")} {renderPinboardName()}
        </div>
      );
    case MessageCardDetail.CODE_1903:
      return (
        <div>
          {getText("formerReportLeader")} {renderPinboardName(LINK)} {getText("latterReportLeader")}
          <span className={styles.strong}>
            {" "}
            {approvalStatus === PinboardShareRequestStatus.AGREE ? getText("hasAgree") : getText("hasReject")}{" "}
          </span>
          {getText("your")} {approvalStatus === PinboardShareRequestStatus.AGREE && PinboardPermission.getPermissionText(visitPermission)}
          {getText("permissionApplication")}
        </div>
      );
    case MessageCardDetail.CODE_1904: {
      const operation = isTrueArray(tokens)
        ? isTrueArray(expectedTokens)
          ? getText("modifySearchTerms")
          : getText("deleteSearchTerms")
        : getText("addSearchTerms");
      return (
        <div>
          {renderWorkspaceName(LINK)}
          <span className={styles.gray}> {operation} </span>
          {originText}
        </div>
      );
    }
    case MessageCardDetail.CODE_1905: {
      const agreeText = approvalStatus === PinboardShareRequestStatus.AGREE ? getText("adopt") : getText("failed");
      return (
        <div>
          {renderWorkspaceName()} {getText("administrators")}
          <span className={styles.strong}> {agreeText} </span>
          {getText("formerCalibrationYouRequested")}
          <span className={styles.link}> {getText("middleCalibrationYouRequested")} </span>
          {getText("latterCalibrationYouRequested")}
          {originText}
        </div>
      );
    }
    case MessageCardDetail.CODE_1906:
      return (
        <div>
          {getName(owner)} {getText("reportsSubscribedForYou")} {renderPinboardName(LINK)} {getText("deliveredOnTime")}
        </div>
      );
    case MessageCardDetail.CODE_1907:
      return (
        <div>
          {getText("pin")} {renderPinboardName(LINK)} {getText("setForYou")}
          <span className={styles.strong}> {renderToSetLoginMethodText()}</span>
        </div>
      );
    case MessageCardDetail.CODE_1908:
      return (
        <div>
          {message.sender?.nickname || ""}
          {getText("setForYou")}
          <span className={styles.strong}> {renderToSetLoginMethodsText()} </span>
          {getText("push")}
          <span className={styles.link}> {alertName} </span>
          {getText("alarm")}
        </div>
      );
    case MessageCardDetail.CODE_2101:
      return (
        <div>
          {getText("welcome")} {renderWorkspaceName(LINK)}
        </div>
      );
    case MessageCardDetail.CODE_2102:
      return (
        <div>
          <span className={styles.link}>{getText("You")} </span>
          {getText("youBecome")} {renderWorkspaceName(LINK)} {getText("manageEffective")}
        </div>
      );
    case MessageCardDetail.CODE_2103:
      return (
        <div>
          <span className={styles.link}>{getText("You")} </span>
          {getText("youLose")} {renderWorkspaceName(LINK)} {getText("managePower")} {renderWorkspaceOwner()} {getText("knowMore")}
        </div>
      );
    case MessageCardDetail.CODE_2901: {
      const permission = approvalStatus === PinboardShareRequestStatus.AGREE && PinboardPermission.getPermissionText(visitPermission);
      return (
        <div>
          <div>
            {getText("formerReportLeader")} {renderPinboardName(LINK)} {getText("latterReportLeader")}
            <span className={styles.strong}>
              {" "}
              {getText(approvalStatus === PinboardShareRequestStatus.AGREE ? "hasAgree" : "hasReject")}{" "}
            </span>
            {getText("former2901")}
            {window.locale === EN_US ? (
              <>
                {permission}
                {getText("middle2901")}
                {getVisitorName()}
              </>
            ) : (
              <>
                {" "}
                {getVisitorName()} {getText("middle2901")} {permission}
              </>
            )}
            {getText("latter2901")}
          </div>
        </div>
      );
    }
    case MessageCardDetail.CODE_2902:
      return (
        <div>
          {getSenderName()} {getText("sharedPin")} {renderPinboardName(LINK)} {getText("toWorkspace")}
        </div>
      );
    case MessageCardDetail.CODE_2903:
      return (
        <div>
          {getSenderName()} {getText("movePin")} {renderPinboardName(LINK)} {getText("toYou")}
          <span className={styles.link}> {getText("you")}</span>
        </div>
      );
    case MessageCardDetail.CODE_2904:
      return (
        <div>
          {getYourGroup()}
          {getText("inPin")} {renderPinboardName(LINK)} {getText("powerChange")} {PinboardPermission.getPermissionText(visitPermission)}
        </div>
      );
    case MessageCardDetail.CODE_2906:
      return (
        <div>
          {getSenderName()}
          <span className={styles.gray}> {getText("applyCalibration")} </span>
          {originText}
        </div>
      );
    case MessageCardDetail.CODE_4901:
      return (
        <div>
          {getName(referrerName)} {getText("applyTo")} {getVisitorName()} {getText("openPin")} {renderPinboardName(LINK)} {getText("power")}
        </div>
      );
    case MessageCardDetail.CODE_4902:
      return (
        <div>
          {getVisitorName()} {getText("pinApply")} {renderPinboardName(LINK)} {getText("power")}
        </div>
      );
    default:
      return "";
  }
};

export default MessageCardContent;
