export default {
  undoChange: "放弃修改",
  cancel: "取消",
  continueEdit: "继续编辑",
  saveChange: "保存修改",
  quiting: "正在退出报告",
  quitPrompt: "是否要保存本次修改的内容？",
  addSuccessToast: "加入报告成功",
  back: "返回",
  share: "分享",
  save: "保存",
  edit: "编辑",
  addFilter: "添加筛选",
  addFilterTooltip: "报告的全局筛选",
  globalConfig: "全局显示设置",
  globalConfigTooltip: "统一设置报告颜色样式等",
  reset: "重置",
  checkMsg: "审核消息",
  refreshFilter: "重置筛选器",
  textBox: "文本框",
  textBoxTooltip: "添加文本或图片",
  addChart: "添加图表",
  addChartToolTip: "添加新图表",
  heading: "章节标题",
  headingToolTip: "添加章节，快速导航",
  more: "更多",
  addTag: "添加标签",
  delete: "删除",
  removeFromList: "从列表中移除",
  noAuth: "无权查看该报告",
  noCard: "报告中暂无卡片",
  please: "，请",
  addCard: "添加卡片",
  undoChangeConfirm: "是否放弃已修改的内容？",
  undoChangePrompt: "点击“放弃修改”您本次修改的内容将不会被保存，同时退出编辑模式。",
  continueToEdit: "继续编辑",
  editPinboardDialogTitle: "编辑标题和简介",
  undo: "放弃",
  complete: "完成",
  add: "添加",
  addInstruction: "添加文本",
  modifyInstruction: "修改文本",
  disablePrompt: "不再提示",
  haveLearned: "我已了解",
  starSuccessToast: "关注成功",
  removeSuccessToast: "移除成功",
  removeFailToast: "移除失败，请稍后试一试",
  starFailToast: "关注失败，请稍后试一试",
  delSuccessToast: "删除成功",
  modifyPinboardSuccessToast: "修改报告成功",
  delCardConfirm: "是否确定删除卡片",
  saveErrorToast: "报告名称不能为空",
  copyPinboardSuccessToast: "复制报告成功",
  open: "打开",
  copyLink: "复制链接",
  owner: "负责人",
  remove: "移除",
  haveStarred: "已关注",
  notStar: "未关注",
  createCopy: "创建副本",
  manageVisitor: "管理访问者",
  rename: "重命名",
  pinboardInfo: "报告信息",
  renamePinboard: "重命名报告名称",
  noAuthPrompt: "没有数据权限，请联系工作区管理员",
  noCardPrompt: "加载卡片失败，请联系工作区管理员",
  noMeasureAvailable: "卡片中的指标失效，请联系报告负责人",
  noModelPrompt: "数据已失效，请联系工作区管理员",
  exportSuccessText: "导出完成！",
  exportFailText: "导出失败！",
  exportingPDFText: "正在导出PDF文件…",
  exportingPPTXText: "正在导出PPTX文件…",
  exportAs: "导出为",
  exportAsTip: "导出已加载的数据",
  appendixDocText: "附录",
  pinboardIntroDocText: "报告简介：",
  recovery: "恢复",
  exitDetected: "检测到上次异常退出",
  editsNotSaved: "上次编辑报告退出时未保存，",
  whetherToRestore: "是否要恢复上次编辑的内容？",
  maxCacheTitle: "浏览器缓存超过上限",
  maxCachePrompts1: "检测到上次异常退出，但浏览器缓存超过上限，无法恢复上次编辑内容。",
  maxCachePrompts2: "建议编辑时压缩上传图片的大小。",
  replaceSuccessToast: "替换成功",
  editCardTitle: "编辑",
  insightPreviewTitle: "浏览",
  insightPreviewTitleTip: "智能解析",
  replaceCardBtn: "替换卡片",
  duplicateCardBtn: "复制卡片",
  duplicateCardToBottom: "复制到底部",
  duplicateCardToBelow: "复制到下方",
  deleteCardBtn: "删除卡片",
  editStyle: "编辑样式",
  moreOperation: "更多",
  saveSuccess: "保存成功",
  cacheSettings: "缓存设置",
  cardSizeTitle: "卡片尺寸",
  cancelStarSuccessToast: "已取消关注",
  cancelStarFailToast: "取消关注失败",
  filter: "筛选器",
  willClearExpiredFilters: "已失效，系统将为你清除已失效的筛选器",
  gotIt: "知道了",
  transformToTemplate: "转化为模版",
  transformTemplateSuccessToast: "转换为模板成功",
  filterInCardPopoverTitle: "该图表的筛选信息",
  templateFrom: "该模版由",
  clickToApply: "创建，可一键使用",
  applyTemplate: "应用模板",
  from: "来自",
  created: "创建",
  selectTemplate: "也可选择一个模板，添加内容：",
  moreTemplate: "更多模板",
  tag: "标签",
  timedReminder: "定时提醒设置",
  accomplish: "完成",
  enterTextInfo: "输入文本",
  uploadImageFailedTip: "上传失败，请重新上传。",
  pinboardInfoTitle: "报告信息",
  baseInfoTitle: "基础信息",
  countInfoTitle: "流量统计",
  lastModifierLabel: "最近更新人",
  updatedAtLabel: "更新时间",
  createdAtLabel: "创建时间",
  chartNumLabel: "图表数",
  textNumLabel: "文本框数",
  totalVisitedNumLabel: "总访问次数",
  totalVisitedUserNumLabel: "总访问人数",
  rangeVisitedNumLabel: "期间访问次数",
  rangeVisitedUserNumLabel: "期间访问人数",
  lineSetting: "折线设置",
  dimensionColorSetting: "维度值颜色覆盖",
  pieLabelSetting: "饼图/漏斗图数据标签设置",
  dimensionColorSettingTip: "添加上限为10个，将会统一覆盖报告中当前图表中该维度值的颜色",
  cascaderPlaceHolder: "输入关键词查找",
  globalConfigResetConfirmTip: "重置后所有显示设置将恢复至未做修改前的状态。",
  globalConfigResetConfirmOkText: "确定重置",
  searchFilterApplyNotificationTitle: "报告已自动应用筛选",
  expandTocButtonTooltip: "展开导航",
  unExpandTocButtonTooltip: "收起导航",
  headingsTitle: "章节导航",
  associatedPinboardTitle: "关联报告",
  errorWorkspaceTitle: "切换工作区前往报告",
  errorWorkspaceContent1: "你当前所在工作区与报告所在工作区不一致",
  errorWorkspaceContent2: "需要切换工作区才能查看",
  errorWorkspaceOKText: "确认切换",
  errorWorkspaceCancelText: "留在当前",
  errorLimitTitle: "缺少报告权限",
  errorLimitTitleContent1: "无法找到报告，可能由于报告不存在或你不在报告所在工作区。",
  errorLimitTitleContent2: "无法查看此报告",
  errorLimitTitleOKText: "知道了",
  batchDeleteButton: "批量删除",
  batchDeleteText1: "已选 ",
  batchDeleteText2: "张图表",
  batchDeleteText3: "个文本框",
  batchDeleteButtonOkText: "确定删除",
  batchDeleteConfirmTitle: "确认删除已选图表",
  batchDeleteTooltip: "批量删除报告内的图表或文本框",
};
