export default {
  measure: "指标",
  dimension: "维度",
  timeDimension: "时间维度",
  addFilter: "添加筛选",
  noResultFound: "查找无结果，请更换关键字。",
  noSelectPrompt: "需要选择你想应用的卡片",
  selectionNotSupported: "选择框置灰的卡片不支持应用本筛选",
  noCardApplied: "暂未应用卡片",
  repeatFiltered1: "已应用同",
  repeatFiltered2: "筛选",
  cancel: "取消",
  apply: "应用",
  setDefault: "设置默认值",
  setFilter: "设置筛选值",
  chooseCard: "选择应用的卡片",
  nameAs: "设置筛选器名称",
  nameAsTitle: "筛选器名称",
  viewCard: "应用的卡片",
  to: "至",
  toToday: "至今",
  timeRange: "时间范围",
  batchAdd: "批量输入",
  back: "返回",
  showTags: "显示全部筛选",
  hideTags: "收起筛选",
  all: "全部",
  allFilter: "全部筛选",
  tip: "*将显示满足其一输入条件的数据；搜索可能需要较长的时间。",
  multiSelect: "多选",
  startTime: "开始时间",
  endTime: "结束时间",
  noPermission: "无此数据权限",
  is: "是 ",
  isNot: "不是 ",
  contain: "包含 ",
  notContain: "排除 ",
  with: "以 ",
  start: " 开头",
  end: " 结尾",
  isNull: "为空",
  isNotNull: "不为空",
};
