export default {
  upTo: "截至",
  editChart: "编辑图表",
  numberViewMode: "大数字模式",
  numberViewModeLabel: "大数字设置",
  dataTab: "数据",
  showTab: "显示",
  showMore: "更多",
  seeMore: "查看更多",
  closeGauge: "关闭表盘",
  openGauge: "打开表盘",
  switchChartType: "切换图表类型",
  shareChart: "分享图表卡",
  saveConfigBtnText: "保存配置",
  addToPinboardBtnText: "加入报告",
  addToWPSBtnText: "加入 WPS 文档",
  addToOfficeBtnText: "加入 Word 文档",
  loadingText: "加载中...",
  addToWordLoadingText: "内容加载中......",
  forecastRange: "预测范围",
  forecastPromptPrefix: "有",
  aboveForecastPrompt: "次高于预测上限，总共偏高",
  belowForecastPrompt: "次低于预测下限，总共偏低",
  noSearchResultText: "当前搜索没有数据，请尝试其他搜索",
  ViewAIAnalysisBtn: "查看 AI 对错误的解读",
  aiAnalysisTitle: "AI 错误解读",
  saveToMySavedCardToast: "已保存至“我的-我保存的卡片”",
  updateCardSuccessToast: "更新卡片成功",
  shareCard: "分享卡片",
  saveCard: "保存卡片",
  sendEmail: "发送邮件",
  downloadCSV: "下载CSV",
  downloadPNG: "下载PNG",
  cardAlarm: "卡片提醒",
  updateBtnText: "更新",
  saveAsNewCardBtnText: "另存为新卡",
  updateOrSaveCardConfirmText: "当前卡片已保存，是否更新卡片或另存为新卡片？",
  shareLinkBtnText: "分享链接",
  sendToEmailBtnText: "发送到邮箱",
  dataPointMarkLabel: "数据点标记",
  dataPointNumTagLabel: "数据点数值标签",
  dimensionTagLabel: "维度值标签",
  indicatorFormatLabel: "指标格式",
  proportionFormatLabel: "占比格式",
  smoothCurveLabel: "平滑曲线",
  polymerizeMethod: "聚合方式",
  tableThemeLabel: "表格主题",
  rowHeightLabel: "行高",
  totalLabel: "总计",
  subTotalLabel: "小计",
  subTotalDimensionsLabel: "开启小计的维度",
  tablePageFooter: "表格页脚",
  traditionLabel: "传统",
  classicsLabel: "经典",
  stripesLabel: "现代",
  showText: "显示",
  rowsText: "行",
  totalText: "共计",
  gaugeAxisTickLabel: "刻度值",
  gaugeLabel: "表盘",
  pointerLabel: "指针",
  titleFontSizeLabel: "标题字号",
  maxValueLabel: "最大值",
  minValueLabel: "最小值",
  maxValueTypeLabel: "最大值类型",
  minValueTypeLabel: "最小值类型",
  barLabel: "柱状",
  lineLabel: "折线",
  displayTypeLabel: "展示类型",
  referenceLineLabel: "参考线",
  add: "添加",
  addReferenceLine: "添加参考线",
  editReferenceLine: "编辑参考线",
  colorLabel: "颜色",
  mapColorLabel: "地图颜色",
  bubbleColorLabel: "气泡颜色",
  countDimensionOrMeasureLabel: "统计维度/指标",
  histogramCountType: "区间宽度计算方式",
  histogramCountTypeAverage: "等距分段",
  histogramCountTypeCustom: "手动分段",
  histogramIntervalCount: "区间个数",
  histogramIntervalMax: "区间最大值",
  histogramIntervalMin: "区间最小值",
  histogramIntervalWidth: "区间宽度",
  histogramIntervals: "区间范围",
  addHistogramInterval: "添加区间",
  dataOfInterest: "关注数据",
  yoyGroupShow: "同环比组合展示",
  colorLabelInfo: "低 → 高",
  dataPointSettingLabel: "数据点设置",
  typeLabel: "类型",
  selectPlaceholder: "请选择",
  showReferenceLineInfoLabel: "显示参考线信息",
  nameLabel: "名称",
  cardFilterCollapseLabel: "卡片副标题",
  cardFilterOptionShow: "展示",
  cardFilterOptionCollapse: "收起",
  cardBorderLabel: "卡片边框",
  cardBorderShow: "展示",
  cardBorderHidden: "隐藏",
  EditPanelInputPlaceholder: "不填写显示默认名称",
  fixedValueLabel: "固定值",
  fixedValueInfo: "请输入-10兆至10兆范围内的数字",
  fixedValueInputPlaceholder: "精确至2位小数",
  percentileLabel: "百分位",
  percentileInputPlaceholder: "请输入0-100的整数",
  rankingLabel: "排名",
  rankingInfo: "请输入1＜X＜维度值数量的整数",
  rankingInputPlaceholder: "请输入",
  rankingAddonBefore: "前",
  confirm: "确定",
  colorSlice: "颜色切片",
  sliceLabel: "切片",
  colorText: "颜色",
  scaleLabelFormat: "刻度标签格式",
  editXAxis: "编辑 X 轴",
  editYAxis: "编辑 Y 轴",
  editZAxis: "编辑 Z 轴",
  xAxisGridLinesLabel: "X轴网格线",
  yAxisGridLinesLabel: "Y轴网格线",
  dimensionNameLabel: "维度值名称",
  indicatorLabel: "指标",
  proportionLabel: "占比",
  allValueLabels: "所有数值标签",
  valueLabels: "数值标签",
  dragDimensionAndMeasureHint: "可拖拽维度/指标到这里",
  size: "大小",
  xAxis: "X轴",
  dragDimensionHint: "可拖拽维度到这里",
  yAxis: "Y轴",
  zAxis: "Z轴",
  timeline: "时间轴",
  dragMeasureHint: "可拖拽指标到这里",
  sizeMustShowOne: "大小处的指标必须显示一个",
  sizeMustShowOnlyOne: "大小处的指标只能显示一个",
  sizeMustOne: "大小不能为空",
  dimensionMustOne: "该图表X轴不能为空。",
  measureMustOne: "Y轴不能为空。",
  measureMustShowOne: "该图表至少需要显示1个指标。",
  mapMustShowOneMeasure: "该图表需要至少一个展示指标（染色指标或气泡指标）。",
  measureOnlyShowOne: "该图表只能显示1个指标。",
  cannotNull: "X或Y轴不能为空。",
  oneAxisOneType: "同1个轴上不能同时放置指标和维度。",
  oneAxisOnlyOneShowMeasure: "X或Y轴上只能展示1个指标。",
  oneAxisMustShowOneMeasure: "X或Y轴上至少需要展示1个指标。",
  cannotShowNegatives: "该类型图表无法显示同时含有正负数的指标",
  cannotHideUniqueMeasure: "该类型图表不能隐藏唯一的指标",
  edit: "编辑",
  cancel: "取消",
  colorDepth: "颜色深浅",
  displayDimension: "展示维度",
  displayMeasure: "展示指标",
  colorMeasure: "染色指标",
  bubbleMeasure: "气泡指标",
  otherMeasure: "其他指标（仅在浮窗展示）",
  row: "行",
  column: "列",
  dragRowAndColumnHint: "可拖拽行或列到这里",
  value: "值",
  dragChangeValueHint: "在行与列之间拖拽可改变值的展示方式",
  valueDisplay: "值的展示",
  columnEmptyPrompt: "列不能为空",
  rowEmptyPrompt: "行不能为空",
  atLeastOneMeasure: "该图表至少需要显示1个指标",
  dragHint: "按住上下拖拽",
  showDataHint: "显示该组数据",
  hideDataHint: "隐藏该组数据",
  editDataHint: "编辑该组数据",
  left: "左侧",
  right: "右侧",
  top: "顶部",
  bottom: "底部",
  hidden: "隐藏图例",
  default: "默认",
  custom: "自定义",
  otherMeasures: "其他指标",
  number: "数字",
  percentage: "百分比",
  defaultStyle: "默认样式",
  scientificNotation: "科学计数法",
  null: "无",
  tenThousand: "万",
  hundredMillion: "亿",
  trillion: "兆",
  locationLabel: "位置",
  categoryLabel: "类别",
  legendOptionsLabel: "图例设置",
  numDisplayStyleLabel: "数值展示样式",
  decimalDigitsLabel: "小数位数",
  inputDecimalDigitsPrompt: "请输入 0-10 的整数",
  splitNumberLabel: "分割段数",
  splitNumberTip: "分隔值保留两位小数，如0-100分割段数为3，则刻度分别为0、33.33、66.67、100",
  FillIntPrompt: "请填写整数",
  choiceMeasuresPrompt: "选择一个指标",
  negativeDisplayFormatLabel: "负数展示格式",
  thousandthSeparatorLabel: "千分位分隔符",
  thousandthSeparatorInfo: "打开后100000会显示100,000",
  monetaryUnitLabel: "货币单位",
  typeNotSupportToast: "抱歉，目前数据不支持切换该类型",
  wordCloudNotSupportToast: "抱歉，目前移动端不支持该类型",
  sankeyLimitTip: "您搜索问题里的不同维度包含相同的值，无法展示该图，请切换其他图表或",
  sankeyLimitSubTip: "表格",
  shareContentTitle: "共享",
  rollup: "上钻",
  drillDown: "下钻",
  copyText: "复制",
  noFieldTip: "找不到对应字段",
  copySuccessToast: "复制成功",
  copyFailToast: "复制失败，请稍后重试",
  backToPreviousLevel: "返回上一层",
  drillInputPlaceholder: "搜索想钻取的维度",
  noDrillItemTip: "无可用钻取维度",
  timeScale: "时间尺度",
  actionFilter: "快捷筛选",
  disableActionFilterTip: "报告内无应用在本卡片上的相关筛选器",
  disableActionFilterTip1: "该图表暂不支持筛选",
  selectOneTip: "请选择单个单元格进行钻取",
  cellNoDataTip: "当前单元格无内容",
  chartOverMaxDataPointTip: "您搜索的数据超出图表数据点显示上限，请尝试进一步筛选减少数据点。 您可缩小时间区间、减少维度个数。",
  formerOverMaxDataPointTip: "您搜索的数据超出",
  pivotTableOverMaxColCountTip: "该图表包含过多数据列（超过 200 列），请将部分维度从列转移到行或进一步筛选减少数据点。",
  latterOverMaxDataPointTip: " 个数据点显示上限，请尝试进一步筛选减少数据点。 您可缩小时间区间、减少维度个数，或",
  legendOverMaxTip: "该图表包含过多图例（超过 100 个），请尝试进一步筛选减少数据点。",
  legendOverMaxHelpTip: "如减少指标数量、减少维度值数量，或",
  switchTable: "切换表格",
  view: "查看。",
  changeColor: "更改配色",
  formerTotalLegendNum: "共",
  latterTotalLegendNum: "个图例",
  axisSortMenusDefault: "默认",
  axisSortMenusUp: "升序",
  axisSortMenusDown: "降序",
  axisSortMenusName: "数据排序",
  axisPositionMenusTop: "上方",
  axisPositionMenusBottom: "下方",
  axisPositionMenusName: "位置",
  axisPositionMenusLeft: "左侧",
  axisPositionMenusRight: "右侧",
  axisGroupMenusName: "分组",
  userThresholdValueTip: "因搜索结果数据超过设置阈值，数据仅以表格形式呈现。可联系管理员修改阈值。",
  ownerThresholdValueTip: "因搜索结果数据超过设置阈值，数据仅以表格形式呈现。",
  thresholdValueSetLink: "阈值设置",
  increase: "增加",
  decrease: "减少",
  captionCurrentKey: "当期为",
  change: "变化",
  changeAccountsFor: "变化占比",
  tagDateRangeError: "标签起止日期范围参数错误",
  unsupportedDateTypeError: "不支持的日期类型参数",
  switch: "切换",
  forecastBtnText: "预测",
  replaceCardBtnText: "替换卡片",
  cardExceedContext: "该图超出趋势图横坐标最大展示上限，请尝试进一步筛选减少数据点。",
  cardExceedTip: "如将时间单位改为周，或缩小时间范围。",
  trendLine: "拟合曲线",
  linear: "线性回归",
  exponential: "指数回归",
  logarithmic: "对数回归",
  polynomial: "多项式回归",
  heatMapModel: "热图模式",
  heatMapModelTip: "开启热图的指标",
  barViewModel: "数据条",
  barViewModelTip: "开启数据条的指标",
  sort: "排序",
  position: "位置",
  total: "总计",
  remove: "移除",
  funnelPercentLabel: "百分比",
  funnelCalcTypeLabel: "百分比计算方式",
  axisFillRangePlaceholder: "-10兆~10兆",
  axisFillRangeLabel: "显示范围",
  axisMaxValueLabel: "最大值",
  axisMinValueLabel: "最小值",
  axisIntervalTypeLabel: "刻度间隔",
  axisIntervalValueLabel: "刻度间隔值",
  axisLogarithmicLabel: "对数刻度",
  axisReverseLabel: "逆序刻度值",
  axisFillRangePrompt: "请填写-10兆~10兆范围的数字",
  axisMinValuePrompt: "须小于最大值",
  axisMaxValuePrompt: "须大于最小值",
  axisLogPrompt: "对数刻度下，最小值须大于0",
  insightsBtnTip: "智能解析",
  disableAddBtnTip: "关闭智能解析后即可加入报告",
  disableSelectBtnTip: "关闭智能解析后即可切换图表类型",
  disableEditBtnTip: "关闭智能解析后即可编辑图表",
  disableMoreBtnTip: "关闭智能解析后即可进行更多操作",
  insightsPanelTitle: "智能解析",
  insightParetoTitle: "符合 80/20 构成",
  insightSeasonalityTitle: "周期性波动",
  insightSeasonalityChartXAxisName: "统计周期",
  insightSeasonalityChartYAxisName: "平均波动",
  insightTrendTitle: "总体变化趋势",
  insightVolatilityTitle: "波动性变高",
  insightAverageTitle: "均值",
  insightStatisticsTitle: "离散统计值",
  insightTextTitle: "数据解释",
  insightsErrorMsg: "未能获取解析，请稍后再试",
  insightCorrelationTitle: "相关性分析",
  insightRecentChangesTitle: "最新变化",
  insightMixedTrendsTitle: "趋势差异",
  retryBtnText: "重试",
  viewBtnText: "查看解析",
  viewDetailBtnText: "查看详情",
  viewAllBtnText: "查看所有解析",
  cumulativeProportion: "累计百分比",
  yAxisCannotNull: "Y轴不能为空",
  oneYAxisMustShowOneMeasure: "Y轴上至少需要展示1个指标",
  allDataPoint: "所有数据点",
  internalPoint: "内部点",
  outlier: "异常点",
  cabinet: "箱体",
  dataPoint: "数据点",
  gridLinesLabel: "网格线",
  min: "下限",
  Q1: "下四分位数",
  median: "中位数",
  Q3: "上四分位数",
  max: "上限",
  unicode: "：",
  cardTour: "在这张卡片上的内容是与当前问题直接结果强相关的数据",
  selectChartTour: "在这里改变图表显示的类型",
  attributionTour: "🌟你能在这张卡片里获得数据洞察。系统自动比较了同比变化，并找出了影响最大的因素，同时还展示了异常的数据。",
  attributionBtnTour: "点击“更多”按钮查看详细分析",
  nextStepBtn: "下一步",
  tryBtn: "试试",
  okBtn: "好的",
  dataLoadingText: "正在取数",
  chartLoadingText: "正在生成",
  chartRenderingText: "正在渲染",
  checkBtnText: "立即查看",
  insightsTourContent: "✨点击这里查看智能解析，自动为你总结图表中的重要信息，比如异常值，周期性，波动性等。",
  otherLabel: "其他",
  dimensionConfigLabel: "维度展示设置",
  defaultSelectLabel: "全部展示",
  numberSelectLabel: "设置展示数量",
  percentSelectLabel: "设置展示比例",
  thresholdSelectLabel: "设置展示阈值",
  numberLabel: "维度展示数量",
  percentLabel: "维度展示占比",
  thresholdLabel: "维度展示阈值",
  enterIntPlaceholder: "输入整数",
  addonAfterText: "个",
  compare: "较",
  inspectionPanelTitle: "洞察解析",
  unableSortOrTotal: "由于行数过多无法进行排序/总计",
  highLabel: "高",
  middleLabel: "中",
  lowLabel: "低",
  downloadFailedToast: "下载失败",
  calcLabel: "计算方式",
  solidColorLabel: "固定色",
  gradientColorLabel: "渐变",
  viewForecastBtnText: "查看预测",
  okBtnText: "好的",
  forecastTourContent: "🔮 想看对该指标的预测，点击这里生成。",
  animationBarMessage: "动态图仅展示前20行数据",
};
