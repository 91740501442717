export default {
  You: "You",
  yourAccountBecome: "Your account has been upgraded from ",
  updated: " to",
  accountBecome: "Account updated to ",
  account: " account",
  currentAccount: "You account is ",
  accountWill: " account, will expire in ",
  expired: ", you can no longer sign in after account expiration",
  formerWillExpiredOneDay: "You account will expire in ",
  oneDay: "1 day",
  latterWillExpiredOneDay: "",
  removeYou: "Are removed from ",
  contactAdmin: ", please contact workspace admin",
  knowMore: "Know more",
  pin: "Dashboards",
  deleted: "Deleted",
  formerRemovedPin: "Removed",
  latterRemovedPin: "From dashboard",
  welcome: "Welcome to ",
  join: "Workspace",
  youBecome: "have become the admin of",
  manageEffective: ", admin access will be effective on the next time",
  youLose: "No longer have",
  managePower: "Workspace admin access, please contact workspace admin",
  sharedPin: "Dashboard shared to ",
  toWorkspace: " workspace",
  movePin: "Has transferred the authority of the person in charge of dashboard ",
  toYou: "To ",
  inPin: "access in dashboard",
  powerChange: " has been changed to ",
  applyTo: "Access for ",
  openPin: " to dashboard",
  power: "",
  pinApply: "Request dashboard access",
  applyCalibration: "Submitted a feedback",
  modifySearchTerms: "Modify search terms",
  deleteSearchTerms: "Delete search terms",
  addSearchTerms: "Add search terms",
  adopt: "Adopt",
  failed: "Failed",
  administrators: "Administrators",
  reportsSubscribedForYou: "Reports subscribed for you",
  deliveredOnTime: "Delivered on time",
  setForYou: "Set",
  push: "Alert for measure",
  mailboxPush: "Mailbox push",
  dingtalkPush: "DingTalk robot timed push",
  larkPush: "Lark robot timed push",
  wecomPush: "Enterprise WeChat robot timed push",
  loggedOutUsers: "Deleted user",
  deletedUserGroup: "[deleted user group]",
  you: "You",
  yourIn: "Your ",
  userGroup: "User group",
  deletedReports: "[deleted dashboards]",
  reducedTo: "Downgraded to",
  alarm: "",
  robotPush: "Robot timed push",
  formerReportLeader: "The person in charge of report",
  latterReportLeader: "",
  hasReject: "Has rejected to ",
  hasAgree: "Has agreed to ",
  your: "Your",
  permissionApplication: "Permission application",
  formerCalibrationYouRequested: "The feedback ",
  middleCalibrationYouRequested: "You ",
  latterCalibrationYouRequested: "Submitted",
  former2901: "the",
  middle2901: "permission you requested for ",
  latter2901: "",
  former1101: "",
  latter1101: "is deleted",
  defunctWorkspace: "Defunct Workspaces",
};
