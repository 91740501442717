export default {
  tag: "Tag",
  create: "Create",
  tagSelectPlaceholder: "Please select or create a tag",
  createFailed: "Failed to create a new tag",
  tagManageTitle: "Tag Manage",
  tagSearchPlaceholder: "Enter keywords for search",
  cancel: "Cancel",
  save: "Sure",
  tableName: "Tag Name",
  pinboardNumber: "Associated report count",
  shortcuts: "Shortcuts",
  updateShortcutsSuccess: "Shortcuts modified successfully",
  updateShortcutsFailed: "Failed to modify the shortcuts",
  action: "Action",
  updateTagSuccess: "Tag modified successfully",
  updateTagFailed: "Failed to modify the tag",
  deleteTagSuccess: "Tag deleted successfully",
  deleteTagFailed: "Failed to delete the tag",
  deleteConfirmTitle: "Confirm deletion of this tag?",
  deleteConfirmContent: "Deleting the tag does not affect the report itself",
  searchNoDataTip: "No results found. Please change the keyword.",
};
