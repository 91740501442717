export default {
  customDataset: "自定义数据集",
  customDatasetTip: "可以在此处事先定义满足某些条件的数据集，在搜索时直接使用关键词进行搜索（例如营销中标记不同标签的用户群）",
  searchInputPlaceholder: "请输入想搜索的信息",
  addBtnText: "新建",
  addSuccessToast: "创建成功",
  deleteSuccessToast: "删除成功",
  saveSuccessToast: "修改成功",
  editBtnText: "编辑",
  deleteBtnText: "删除",
  deleteConfirmTitle: "确认删除该自定义数据集？",
  confirmBtnText: "确认",
  cancelBtnText: "取消",
  keywords: "关键词",
  keywordsInputPlaceholder: "请填写搜索时可以用于搜索该数据集的关键词",
  question: "用于标记条件的问题",
  source: "来源模型",
  dimension: "所选维度",
  operator: "操作人",
  updatedAt: "编辑时间",
  operations: "操作",
  userInputLabel: "用户输入",
  okBtnText: "保存",
  createCustomDatasetSuccessTip: "创建自定义数据集成功",
  updateCustomDatasetSuccessTip: "修改自定义数据集成功",
  datasetConditionLabel: "数据集条件",
  sourceModel: "来源模型",
  questionLabel: "试跑问题",
  questionInputPlaceholder: "请填写用于标记条件的问题",
  tryQuestionButtonText: "试跑问题",
  cardQuestion: "实际标记的条件",
  cardQuestionPlaceholder: "请试跑问题后查看",
  dimensionLabel: "数据集所选维度",
  dimensionSelectPlaceholder: "请试跑后再选择",
  datasetPreview: "数据集预览",
};
