export default {
  name: "User group name",
  nameInputPlaceholder: "Please enter a user group name",
  nameInputErrMsg: "Please enter a user group name",
  description: "User group introduction",
  descriptionInputPlaceholder: "Please enter the user group introduction",
  numberUsers: "Number of users",
  autoAddUsers: "Add users automatically",
  disableDownload: "Disable download",
  on: "Open",
  off: "Off",
  operations: "Settings",
  settingsBtn: "Settings",
  editBtnText: "Members Settings",
  deleteBtnText: "Delete",
  deleteConfirmTitle: "Confirm to delete this user group?",
  confirmBtnText: "Confirm",
  cancelBtnText: "Cancel",
  addBtnText: "Add user group",
  searchGroupInputPlaceholder: "Please enter the information of the user group you want to search for",
  addUserGroupModalTitle: "Add user group",
  saveSuccessToast: "Modified successfully",
  groupOptionsModalTitle: "User group settings",
  batchAddAccountBtnText: "Batch Import",
  uploadLabel: "Update Method",
  addUserBtn: "Add a new user",
  coverUserBtn: "Covering users",
  uploadTypeTip: "It will only add or modify the member information of the current user group, not delete it.",
  uploadFailedToast: "upload failed",
  loginName: "Login Name",
  formatTip1: "Based on the login name, add users in the current workspace to this user group in batches",
  formatTip2: "Supported format: .csv",
  formatTip3: "Supported encoding formats: utf-8, gbk, ascii, utf-16",
  fileModelName: "User Group Membership Template.csv",
  downloadSuccessToast: "Download successful",
  uploadSuccessToast: "File upload successful",
  uploadModalTitle: "Batch Upload",
  uploadBtnText: "Select File",
  downloadBtnText: "Download Template",
  downloadDataBtnText: "Download Current Members",
  uploadTip: "Please download the template and fill it out according to the template format before uploading",
  uploadWarningTitle: "Invalid user warning",
  uploadWarningDesc: "Please download the invalid user list information to view the specific reasons",
  uploadWarningBtnText: "Download Invalid User List",
  warningFile: "Invalid User List",
  userDoesNotExist: "User does not exist",
  notInWorkspace: "User is not in the workspace",
  WrongUser: "Wrong User",
  ErrorMessage: "Error message",
  uploadSuccessTitle: "Upload Successfully",
  uploadAddDesc: "Number of new users:",
  unicode: ":",
};
