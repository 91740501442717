const UpBend = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8106 5.32428C13.8893 5.29626 13.974 5.28101 14.0623 5.28101C14.1826 5.28101 14.2964 5.30935 14.3972 5.35973C14.4371 5.37965 14.4756 5.40335 14.5121 5.43083C14.5432 5.45412 14.5723 5.47976 14.5994 5.50748L18.4986 9.40673C18.7915 9.69962 18.7915 10.1745 18.4986 10.4674C18.2057 10.7603 17.7308 10.7603 17.4379 10.4674L14.8123 7.84175V15.4055C14.8123 16.4332 14.4041 17.4188 13.6774 18.1454C12.9507 18.8721 11.9651 19.2804 10.9375 19.2804H5.46899C5.05478 19.2804 4.71899 18.9446 4.71899 18.5304C4.71899 18.1162 5.05478 17.7804 5.46899 17.7804H10.9375C11.5673 17.7804 12.1714 17.5302 12.6167 17.0848C13.0621 16.6394 13.3123 16.0354 13.3123 15.4055V7.84158L10.6865 10.4674C10.3936 10.7603 9.91873 10.7603 9.62583 10.4674C9.33294 10.1745 9.33294 9.69962 9.62583 9.40673L13.5319 5.50068C13.6133 5.41923 13.7089 5.36043 13.8106 5.32428Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UpBend;
