const DownBend = props => {
  return (
    <svg {...props} width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8106 18.6757C13.8893 18.7037 13.974 18.719 14.0623 18.719C14.1826 18.719 14.2964 18.6906 14.3972 18.6403C14.4371 18.6204 14.4756 18.5967 14.5121 18.5692C14.5432 18.5459 14.5723 18.5202 14.5994 18.4925L18.4986 14.5933C18.7915 14.3004 18.7915 13.8255 18.4986 13.5326C18.2057 13.2397 17.7308 13.2397 17.4379 13.5326L14.8123 16.1582L14.8123 8.59448C14.8123 7.5668 14.4041 6.58122 13.6774 5.85455C12.9507 5.12788 11.9651 4.71964 10.9375 4.71964L5.46899 4.71964C5.05477 4.71964 4.71899 5.05542 4.71899 5.46964C4.71899 5.88385 5.05477 6.21964 5.46899 6.21964L10.9375 6.21964C11.5673 6.21964 12.1714 6.46984 12.6167 6.91521C13.0621 7.36058 13.3123 7.96463 13.3123 8.59448L13.3123 16.1584L10.6865 13.5326C10.3936 13.2397 9.91873 13.2397 9.62583 13.5326C9.33294 13.8255 9.33294 14.3004 9.62583 14.5933L13.5319 18.4993C13.6133 18.5808 13.7089 18.6396 13.8106 18.6757Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownBend;
