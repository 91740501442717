export default {
  ascendText: "点击降序",
  descendText: "取消排序",
  defaultText: "点击升序",
  createSuccessToast: "报告新建成功",
  allPinboards: "全部报告",
  starred: "已关注",
  unStarred: "未关注",
  sharedWithMe: "与我分享",
  myPinboards: "我的报告",
  recycleBin: "回收站",
  delPinboardToast: "删除报告成功",
  restoreSuccessToast: "恢复成功",
  delSuccessToast: "删除成功",
  restore: "恢复",
  delPermanently: "彻底删除",
  sharePinboard: "报告分享",
  delete: "删除",
  remove: "移除",
  cancelStar: "取消关注",
  pinboardName: "报告名称",
  tag: "标签",
  owner: "负责人",
  lastModifier: "最近更新人",
  operation: "操作",
  loading: "加载中...",
  backToTop: "回到顶部",
  create: "新建",
  searchText: "输入关键词检索",
  updatedAt: "更新时间",
  noResultFound: "查找无结果，请更换关键词",
  noRecordFound: "当前列表暂无内容",
  noPinboardInList: "当前列表暂无报告",
  createPinboard: "新增报告",
  noContentInRecycleBin: "您的回收站空空如也",
  noContentPrompt1: "暂无内容，去",
  noContentPrompt2: "创建报告",
  noContentPrompt3: "吧",
  cancel: "取消",
  tags: "标签",
  editSuccessToast: "修改成功",
  markPinboardDialogTitle: "标记报告",
  properTagHint: "用合适的标签标记报告可使其更容易被找到",
  templateLibrary: "模板库",
  transformTemplateSuccessToast: "转换为模板成功",
  modifyPinboardSuccessToast: "修改报告成功",
  template: "模板",
  timedReminder: "定时提醒设置",
  all: "全部",
  renderNull: "无",
  tagManage: "标签管理",
  associatePinboardTitle: "关联报告",
  associatePinboardTitleDesc: "关联后可以在本报告的快捷导航处快速点击跳转",
  associatePinboardModalAddButton: "添加关联",
  associatePinboardAddTitle: "添加关联报告",
  associatePinboardAddTitleDesc: "你只能关联你有权限浏览或编辑的报告",
  confirmRemoveAssociatedPinboardText: "确认移除此关联报告",
  confirmBatchRemoveAssociatedPinboardText: "确认移除选中的关联报告",
  batchRemove: "批量移除",
  removeSuccessToast: "移除成功",
  addSuccessToast: "添加成功",
  addText: "添加",
  selected: "已选",
  confirm: "确认",
  selectedAssociatedPinboardAfterText: "个关联报告",
  searchPlaceholder: "输入关键字查找",
};
